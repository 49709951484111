.am-tab-bar{
    position:fixed;
    bottom:0;
    width:100%;
    height:80px;
    .am-tabs-tab-bar-wrap{
        height:80px
    }
    .am-tab-bar-bar{
        height:80px
    }
    .am-tab-bar-tab-icon img{
        height:50px;
        width:50px
    }
    .am-tab-bar-tab{
        border:solid rgb(224, 224, 224);
        border-width:0 1px
    }
}

.sticky-header{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 233
}

#chat-page{
    // .am-list-body{
        
    // }

    // #listwindow{
    //     position: fixed;
    //     height:200px;
    //     margin-top:50px;
    //     margin-bottom: 50px;
    //     overflow: hidden;
    // }

    .chat-ore{
        .am-list-extra{
            flex-basis: auto;
        }
        .am-list-content{
            padding-right: 15px;
            // text-align: right;
        }
        
    }   
    .send-bar{
        position:fixed;
        width:100%;
        bottom:0;
        .slider-frame{
            overflow: show!important;
        }
    }
} 

.am-tab-bar-bar .am-tab-bar-tab-icon .tab-badge :last-child{
    left:50px;
}

.am-activity-indicator.am-activity-indicator-toast{         //toast遮挡全屏不让点击，让其下移一段距离，能让用户点击返回键
    top: 50px
}

.am-activity-indicator-content{
    margin-bottom: 50px;
}
@font-size-base: 12px;@text-color: rgba(0, 0, 0, .85);